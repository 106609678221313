.exchange-switcher-container {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .cta-text {
    color: #C4C4C4; /* Matrix green */
    font-family: 'open sans', sans-serif;
    font-size: 20px;
    margin-right: 10px; /* Adjust as needed */
  }
  
  .exchange-switcher {
    background-color: #303844;
    color: #fff; /* Matrix green */
    border: 1px solid #303844; /* Matrix green */
    font-family: 'open sans', sans-serif;
    font-size: 20px;
    padding: 4px;
    outline: none;
    border-radius: 3px;
  }
  
  .exchange-switcher:focus {
    border-color: #F4B62A; /* Matrix green */
  }
  
  .exchange-switcher option {
    background-color: #303844;
    color: #c4c4c4; /* Matrix green */
  }
  