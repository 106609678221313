.profile-container {
  background-color: #f5f5f5;
  border-radius: 3px;
  padding: 20px;
  margin: 20px 20px;
  max-width: 30%;
}

@media screen and (max-width: 1050px) {
  .profile-container {
    max-width: 100%;
  }
}

.profile-details {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.profile-details h2 {
  font-size: 24px;
   color: #333;
   margin-bottom: 10px;
}

.profile-details .delete-button {
  background-color: #ff0000;
  color: #c4c4c4;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
}

.profile-details .delete-button:hover {
  background-color: #cc0000;
}
