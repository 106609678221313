.login-button {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: 300px;
  border-radius: 3px;
  border: none;
  background: #F4B62A;
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 50px;
  max-height: 50px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
}

.login-input {
  width: 300px;
  font-size: 14px;
  color: #444;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 50px;
}

.login-input[type="text"],
.login-input[type="password"] {
  height: 30px;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Make it full screen */
}

.rotating-logo {
  width: 80px; /* Adjust the size */
  height: 80px;
  animation: spin 1.5s linear infinite; /* Rotation animation */
}

.twofa-spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: transparent;
}

.twofa-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 5px;
  margin-top: 50px;
  background-color: transparent;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


