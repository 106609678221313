.footer {
  display: flex;
  align-items: center;
  justify-content: space-between; /* This will push the items to the edges */
  background-color: #1C212B;
  color: #c4c4c4;
  padding: 12px 16px;
  position: relative;
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
}

.footer-text {
  font-size: 12px;
}

.footer__title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.social-link {
  margin-right: 0px;
}

.footer__items {
  display: flex;
  align-items: center;
  font-size: 100%;
}

img{
  width: 20px;
}

.footer-link {
  font-size: 16px;
  text-decoration: none;
  color: #c4c4c4;
  background-color: transparent;
  padding: 0px 8px; /* Adjusted padding for spacing between links */
  border-radius: 0px;
  transition: background-color 0.3s ease;
  position: relative;
}

.footer-link:hover {
  color: #d4d4d4;
}
