/* General Styles */
.page-container {
    background-color: #1C212B;
    color: #c4c4c4;
    font-family: Arial, sans-serif;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* HERO SECTION */
.hero {
    background: linear-gradient(to right, #1F2937, #374151);
    text-align: center;
    padding: 50px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.hero h1 {
    font-size: 2rem;
    color: #F4B62A;
}

.hero p {
    font-size: 1rem;
    max-width: 600px;
    margin: 10px auto;
}

.hero-button {
    background: #F4B62A;
    color: #1C212B;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.hero-button:hover {
    background: #e0a021;
}

/* GRID SYSTEM */
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.grid-item {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    text-align: center;
    font-weight: bold;
}

/* HOW IT WORKS - Different Colors */
.work-step {
    color: #1C212B;
    font-weight: bold;
}

.step-1 {
    background-color: #F4B62A; /* Gold */
}

.step-2 {
    background-color: #22C55E; /* Green */
}

.step-3 {
    background-color: #3B82F6; /* Blue */
}

/* FEATURES - Different Colors */
.feature-box {
    color: #1C212B;
}

.feature-1 {
    background-color: #EF4444; /* Red */
}

.feature-2 {
    background-color: #8B5CF6; /* Purple */
}

.feature-3 {
    background-color: #F97316; /* Orange */
}

/* FAQ */
.faq {
    padding: 50px 20px;
}

.faq-container {
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    background-color: #272D3A;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
}

/* CALL TO ACTION */
.cta {
    background-color: #374151;
    text-align: center;
    padding: 40px 20px;
    border-radius: 8px;
    margin-top: 30px;
}

.cta-button {
    background: #F4B62A;
    color: #1C212B;
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 10px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

.cta-button:hover {
    background: #e0a021;
}

/* 🔽 MOBILE RESPONSIVENESS 🔽 */
@media (max-width: 768px) {
    .page-container {
        width: 95%;
        padding: 15px;
    }

    .hero {
        padding: 40px 15px;
    }

    .hero h1 {
        font-size: 1.8rem;
    }

    .hero p {
        font-size: 0.9rem;
    }

    .hero-button {
        padding: 10px 18px;
        font-size: 0.9rem;
    }

    .grid {
        grid-template-columns: 1fr;
    }

    .faq-container {
        padding: 0 15px;
    }

    .cta {
        padding: 30px 15px;
    }

    .cta-button {
        padding: 10px 18px;
        font-size: 0.9rem;
    }
}
