/* TransferForm.css */
.transfer-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 0 auto;
  }
  
  .transfer-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
  }
  
  .transfer-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .transfer-input {
    width: 280px;
    font-size: 16px;
    color: white;
    padding: 6px;
    border: 1px solid #f4b62a;
    border-radius: 3px;
    background-color: transparent;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    max-height: 40px;
  }
  
  .transfer-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  .account-type-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .account-button {
    width: 130px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background: transparent;
    border: 2px solid #f4b62a;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .account-button:hover {
    background: #f4b62a;
    color: black;
  }
  
  .account-button.selected {
    background: #f4b62a;
    color: black;
  }
  
  .transfer-button {
    color: black;
    font-size: 18px;
    font-weight: 600;
    width: 280px;
    border-radius: 3px;
    border: none;
    background: #f4b62a;
    cursor: pointer;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 50px;
    max-height: 50px;
  }
  
  .transfer-button:hover {
    background: #e0a421;
  }
  
  .response-message {
    margin-top: 15px;
    font-size: 14px;
    color: white;
    text-align: center;
  }
  
/* Modal Styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px; /* Prevents modal from touching edges */
  }
  
  .modal-content {
    background: #1C212B;
    padding: 20px;
    margin-left: -35px;
    border-radius: 8px;
    width: 90%;
    max-width: 350px;
    position: relative;
    text-align: center;
    height: 44%;
  }
  
  /* Title */
  .transfer-title {
    font-size: 22px;
    font-weight: bold;
    color: white;
    margin-bottom: 15px;
  }
  
  /* Inputs */
  .transfer-input {
    width: 100%;
    font-size: 16px;
    color: white;
    padding: 6px;
    border: 1px solid #f4b62a;
    border-radius: 3px;
    background-color: transparent;
    margin-bottom: 10px;
    height: 40px;
  }
  
  .transfer-input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  
  /* Account type buttons */
  .account-type-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .account-button {
    width: 45%;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    color: white;
    background: transparent;
    border: 2px solid #f4b62a;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .account-button:hover, .account-button.selected {
    background: #f4b62a;
    color: black;
  }
  
  /* Transfer Submit Button */
  .transfer-button {
    color: black;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    border-radius: 3px;
    border: none;
    background: #f4b62a;
    cursor: pointer;
    margin-top: 20px;
    height: 45px;
  }
  
  .transfer-button:hover {
    background: #e0a421;
  }
  
  /* Response Message */
  .response-message {
    margin-top: 15px;
    font-size: 14px;
    color: white;
    text-align: center;
  }
  
  /* Close Button at Bottom */
  .close-modal-bottom {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background: transparent;
    border: 1px solid white;
    border-radius: 3px;
    cursor: pointer;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  
  .close-modal-bottom:hover {
    background: white;
    color: black;
  }
  
  /* Mobile Adjustments */
  @media screen and (max-width: 600px) {
    .modal-content {
      width: 95%;
      padding: 15px;
    }
  
    .transfer-title {
      font-size: 20px;
    }
  
    .account-button {
      font-size: 12px;
      width: 48%;
    }
  
    .transfer-button, .close-modal-bottom {
      font-size: 14px;
      height: 40px;
    }
  }
  .percentage-buttons {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    gap: 5px; /* Adds spacing between buttons */

    flex-wrap: wrap; /* Prevents overflow issues */
}

.percentage-btn {
    width: auto; /* Adjusts width based on content */
    background-color: transparent;
    color: #d3d3d3;
    border-color: #e0a421;
    border-radius: 8px;;
    text-align: left; /* Ensures text is aligned to the left */
    flex: none; /* Prevents buttons from stretching */
}
.percentage-btn:hover {
  width: auto; /* Adjusts width based on content */
  background-color: #e0a421;
  color: #333;
  border-color: #e0a421;
  border-radius: 8px;;
  text-align: left; /* Ensures text is aligned to the left */
  flex: none; /* Prevents buttons from stretching */
}
