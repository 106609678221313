.UpdateExchange {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 2rem;
    background-color: #1C212B;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    width: 100%;
    max-width: 1200px;
    margin: 2rem auto;
}

/* Title */
.UpdateExchange h3 {
    margin-bottom: 2rem;
    color: #c4c4c4;
}

/* Exchange Cards Row */
.exchange-row {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;

}

/* Exchange Cards */
.exchange-card {
    background-color: #292F3B;
    border-radius: 6px;
    padding: 1.5rem;
    width: 25%;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    transition: all 0.3s ease;
    margin-top: 10px;
}

/* Highlight active card */
.exchange-card.active {
    box-shadow: 0 0 10px rgba(255, 204, 0, 0.5);
}

/* Header with title and button */
.exchange-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.exchange-header h4 {
    color: #c4c4c4;
    margin: 0;
}

/* Add Button */
.add-btn {
    background: #F4B62A;
    color: #171718;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

.add-btn:hover {
    background: #E3A419;
}

/* Form inside the card */
.exchange-form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

/* Expand form when active */
.exchange-card.active .exchange-form {
    max-height: 300px;
    overflow: visible;
}

/* Form Fields */
.exchange-form label {
    color: #c4c4c4;
    margin-bottom: 0.5rem;
}

.exchange-form input {
    padding: 0.5rem;
    border-radius: 3px;
    border: 1px solid #303844;
    background-color: #333;
    color: #c4c4c4;
    transition: border-color 0.3s ease;
    margin-bottom: 10px;
}

.exchange-form input:focus {
    outline: none;
    border-color: #F4B62A;
}

/* Submit Button */
.submit-btn {
    padding: 0.5rem 1rem;
    color: #171718;
    background-color: #F4B62A;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #E3A419;
}

/* Mobile View */
@media (max-width: 1024px) {
    .exchange-row {
        flex-wrap: wrap;
        justify-content: center;
    }

    .exchange-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .exchange-card {
        width: 100%;
    }
}


.connected-exchange-row {
    display: flex;
    justify-content: left;
    margin-bottom: 20px;
  }
  
  .status-light {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 10px;
  }
  
  .status-light.green {
    background-color: green;
  }
  
  .exchange-card.connected {
    border: 2px solid green;
  }
  
  .manage-btn {
    background-color: transparent;
    color: green;
    border: 1px solid green;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .manage-btn:hover {
    background-color: green;
    color: white;
  }
  