.dash-container {
    background-color: #1C212B;
    color: #c4c4c4;
    font-family: Arial, sans-serif;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.filter-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
}

.filter-btn {
    padding: 8px 12px;
    border: none;
    background-color: transparent;
    color: #EDEDED;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out;
}

.filter-btn.active {
    background-color: transparent;
    border: 1px solid #E3A419;
    color: #ededed;
}

.filter-btn:hover {
    background-color: #E3A419;
    color: white;
}


@media screen and (max-width: 600px) { 
    h1 {
        font-size: 26px;
    }
}