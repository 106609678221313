.ListBots-container {
    display: flex;
    flex-direction: column;
    /* Removed align-items: center; */
    justify-content: center;
    padding: 2rem;
    background-color: #1C212B;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    width: 95%;
    font-size: 14px;
}

.ListBots-container h3 {
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 800;
  margin-top: -15px;
  text-align: left; /* Added to align the text to the left */
}

.ListBots-container table {
  width: 100%;
  border-collapse: collapse;
  color: #c4c4c4;
}

.ListBots-container th, .ListBots-container td {
  border: none; /* Remove all borders */
  border-bottom: 1px solid #303844; /* Add bottom border */
  padding: 0.5rem;
  text-align: left;
  color: #c9c2c2;
}

.ListBots-container th {
  background-color: transparent;
  cursor: pointer;
  border-top: 1px solid transparent;
}

.ListBots-container .search-bar {
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid #303844;
  background-color: transparent;
  color: #c4c4c4;
  width: 30%; /* Changed from 40% to 100% */
  text-align: left; /* Added to align the text inside the search bar to the left */
}
