.CreateBot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the start (left) */
  padding: 2rem;
  background-color: #1C212B;
  border-radius: 3px;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  width: 80%;
  margin: 2rem auto;
  margin-top: -32px;
  font-size: 14px;
  text-align: left; /* Set text alignment to left */
}

.CreateBot h3 {
  margin-top: -15px;
  color: #c4c4c4;
  font-size: 14px;
  font-weight: 600;
}

.CreateBot label {
  margin-bottom: 0.5rem;
  color: #c4c4c4;
  font-size: 14px;
}

.CreateBot input {
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid #303844;
  background-color: transparent;
  color: #c4c4c4;
  transition: border-color 0.3s ease;
  font-size: 14px;
}


.CreateBot label, .CreateBot select, .CreateBot input {
  display: block; /* ensure label is on top */
  width: 100%; /* take the full width of their container */
  margin-bottom: 0.5rem; /* margin-bottom to label for spacing */
}

.CreateBot .input-row > div:last-child {
  margin-right: 0;
}

.CreateBot input:focus {
  outline: none;
  border-color: #F4B62A;
}

.CreateBotButton {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 3px;
  border: none;
  background: #F4B62A;
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 44px;
  max-height: 50px;
}

.CreateBotButton:hover {
  /* You can adjust the hover styles as needed. For now, I'm keeping it simple. */
  background-color: #E3A419;
  color: #000;
}


.CreateBot .input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.CreateBot .input-row > div {
  flex: 0 0 40%;
  margin-right: 1%;
}

.CreateBot .input-row > div:last-child {
  margin-right: 0;
}
.CreateBotContainer {
  position: relative; /* Set to relative so the overlay is positioned relative to this container */
  width: 100%; /* Same width as .CreateBot */
  margin: 2rem auto; /* Same margin as .CreateBot */
  margin-top: 60px; /* Same margin-top as .CreateBot */
}

.ComingSoonOverlay {
  position: absolute; /* Position the overlay absolutely within the container */
  top: 0;
  left: 5%;
  right: 0;
  bottom: 0;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  display: flex;
  align-items: center; /* Center the text vertically */
  justify-content: center; /* Center the text horizontally */
  color: #F4B62A; /* Text color */
  font-size: 1rem; /* Text size */
  font-weight: bold; /* Bold text */
  z-index: 10; /* Ensure the overlay is above the form */
}

.ComingSoonOverlay span {
  background-color: #1C212B; /* Background color of the text */
  padding: 1rem; /* Padding around the text */
  border-radius: 5px; /* Rounded corners */
}

.CreateBot select {
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid #303844;
  background-color: transparent;
  color: #c4c4c4;
  font-size: 14px;
  cursor: pointer;
  width: 100%; /* Ensure the select takes the full width of its container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.CreateBot select:focus {
  outline: none;
  border-color: #F4B62A;
}

/* Add these styles to your CSS file */
.error {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}





