/* General Styles */

  
  /* Form Container */
  .tfa-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 350px;
    margin: 20px auto;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  }
  
  /* Input Fields */
  .tfa-input {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    color: #444;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Prevents extra width from padding */
    background-color: #f8f8f8;
    margin-bottom: 15px;
    transition: 0.3s;
  }
  
  .tfa-input:focus {
    border-color: #F4B62A;
    outline: none;
  }
  
  /* QR Code Image */
  .qrcode {
    margin: 15px 0;
    width: 100%;
    max-width: 220px;
    border-radius: 5px;
    border: 2px solid #F4B62A;
  }
  
  /* Buttons */
  .tfa-button,
  .tfa-button-1 {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    border-radius: 5px;
    border: none;
    background: #F4B62A;
    cursor: pointer;
    margin: 15px 0;
    padding: 12px;
    transition: background-color 0.3s;
  }
  
  .tfa-button-1 {
    background: transparent;
    border: 2px solid #F4B62A;
    color: #F4B62A;
  }
  
  .tfa-button:hover,
  .tfa-button-1:hover {
    background-color: #e0a322;
  }
  
  /* Secret Container with Copy Button */
  .secret-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #222;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    box-shadow: inset 0px 0px 5px rgba(255, 255, 255, 0.1);
    margin-bottom: 15px;
    overflow: hidden;
  }
  
  .secret-key {
    font-size: 14px;
    font-weight: bold;
    color: #F4B62A;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 70%;
  }
  
  .copy-button {
    background-color: transparent;
    border: 2px solid #F4B62A;
    color: #F4B62A;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .copy-button:hover {
    background-color: #f4b62a;
    color: #000;
  }
  
  /* Message Styling */
  p {
    font-size: 14px;
    color: #f0f0f0;
    margin-bottom: 10px;
  }
  
  /* Spinner */
  .twofa-spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: transparent;
  }
  
  .rotating-logo {
    width: 80px;
    height: 80px;
    animation: spin 1.5s linear infinite;
  }
  
  /* Animations */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Mobile Compatibility */
  @media (max-width: 768px) {
    .tfa-form {
      width: 95%;
      padding: 20px;
    }
  
    .secret-key {
      font-size: 12px;
    }
  
    .copy-button {
      font-size: 12px;
      padding: 4px 8px;
    }
  
    .tfa-input {
      font-size: 14px;
    }
  
    .tfa-button,
    .tfa-button-1 {
      font-size: 14px;
      padding: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .tfa-form {
      width: 100%;
      border-radius: 0;
    }
  
    .secret-key {
      font-size: 10px;
    }
  
    .copy-button {
      font-size: 10px;
      padding: 3px 6px;
    }
  
    .tfa-button,
    .tfa-button-1 {
      font-size: 12px;
      padding: 8px;
    }
  
    .qrcode {
      max-width: 150px;
    }
  }
  