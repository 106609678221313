.register-button {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  width: 300px;
  border-radius: 3px;
  border: none;
  background: #F4B62A;
  cursor: pointer;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  height: 50px;
  max-height: 50px;
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin: 0 auto;
}

.register-input {
  width: 300px;
  font-size: 14px;
  color: #444;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f8f8f8;
  margin-bottom: 10px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 50px;
}

.register-input[type="email"],
.register-input[type="password"] {
  height: 30px;
}

/* Loading spinner styles */
.fa-spinner {
  margin-right: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-content {
  background: white;
  width: 50%;
  height: 70vh; /* 70% of the viewport height */
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.terms-scrollable {
  flex-grow: 1;
  overflow-y: auto;
  max-height: 80%; /* Allows scrolling inside */
  padding-right: 10px;
}

.close-modal {
  margin-top: 10px;
  padding: 8px 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-modal:hover {
  background: #0056b3;
}

.terms-link {
  color: #007bff;
  cursor: pointer;
  margin-left: 5px;
}

.terms-link:hover {
  text-decoration: underline;
}


.terms-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.terms-container input {
  margin-right: 5px;
}

.terms-link {

  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
  text-align: left;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 50%;
  text-align: left;
}

.close-modal {
  margin-top: 15px;
  padding: 8px 15px;
  border: none;
  background: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.close-modal:hover {
  background: #0056b3;
}

label {
  text-align: left;
  font-size: 14px;
}
