.trading-platform-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Ensures centering */
    padding: 2rem;
    background-color: #1C212B;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    width: 95%;
    max-width: 1400px; /* Ensures content doesn't shrink too much */
    margin: 60px auto; /* Centers horizontally */
    font-size: 14px;
    overflow-x: auto;
}

.chart-container {
    width: 90%; /* Takes most of the available space */
    max-width: 1200px;
    min-width: 800px; /* Prevents too much shrinking */
}

/* Adjust for mobile screens */
@media (max-width: 1024px) {
    .chart-container {
        min-width: 100%; /* Use full width on smaller screens */
        max-width: 100%;
    }
}

.balance-chart-bot-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1rem;
}

/* Mobile: Stack chart and bot sections vertically */
@media (max-width: 768px) {
    .balance-chart-bot-container {
        flex-direction: column;
        align-items: center; /* Center them when stacked */
    }

    .chart-section, 
    .create-bot-section {
        width: 100%; /* Full width on smaller screens */
        padding: 1rem 0;
    }
}

/* Ensure Market Dropdown and Ticker Info align well */
.dropdown-ticker-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;
}

/* Mobile: Stack dropdown and ticker info */
@media (max-width: 768px) {
    .dropdown-ticker-container {
        flex-direction: column;
        align-items: flex-start;
    }
}

/* Make sure containers don't overflow */
.balance-chart-bot-container > * {
    padding: 1rem;
}

/* Reduce padding on smaller screens */
@media (max-width: 480px) {
    .trading-platform-container {
        padding: 1rem;
        margin: 30px auto; /* Reduce top/bottom margin */
    }

    .dropdown-ticker-container {
        padding: 0.5rem;
    }

    .chart-container {
        width: 100%;
    }
}
