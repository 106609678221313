.prova {
    background-color: rgb(44, 212, 156);
    text-align: center;
    align-items: center;
    margin-left: 30%;
    margin-right: 30%;
  }
@media screen and (max-width: 390px) {
    .prova {
        margin-left: 5%;
        margin-right: 5%;
    }
}

.forgot-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    }

.forgot-input {
    width: 300px;
    font-size: 14px;
    color: #444;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    max-height: 50px;
    }
    
.forgot-input[type="text"],
.forgot-input[type="password"] {
    height: 30px;
}

.forgot-button {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    width: 300px;
    border-radius: 3px;
    border: none;
    background: #F4B62A;
    cursor: pointer;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 50px;
    max-height: 50px;
    }