.pair-switcher-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-top: 20px;
}
.pair-switcher-container p {
  font-size: 16px;
}

.cta-text {
  color: #C4C4C4; /* Matrix green */
  font-family: 'open sans', sans-serif;
  font-size: 16px;
  margin-right: 10px; /* Adjust as needed */
}

.pair-switcher {
  background-color: #303844;
  color: #fff; /* Matrix green */
  border: 1px solid #303844; /* Matrix green */
  font-family: 'open sans', sans-serif;
  text-align: left;
  font-size: 16px;
  padding: 0px;
  outline: none;
  border-radius: 3px;
  width: 160px;
  margin-right: 20px;
}

.pair-switcher:focus {
  border-color: #303844; /* Matrix green */
}

.pair-switcher option {
  background-color: #303844;
  color: #c4c4c4; /* Matrix green */
}
