.App {
  text-align: center;
  display: flex; /* Add this */
  flex-direction: column; /* Add this */
  min-height: 100vh; /* Change height to min-height */
}

.App-container {
  position: relative; /* This ensures the z-index applies correctly */
  /* background: linear-gradient(0deg, #171718, #171718 50%, #F4B62A 340%, #171718); */
  background-color: #111621;
  /* background-color: #fff; */
  background-size: 100% 200%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  min-height: 87vh;
  overflow: hidden; /* This ensures the matrix columns don't overflow */
  z-index: 1; /* This ensures the content is displayed on top of the MatrixRain background */
  flex: 1 0 auto;
}

.matrix-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1; /* This ensures the matrix background is below the main content */
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

.App-header {
  background-color: #1C212B;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #F4B62A; /* Matrix green */
}

.footer {
  text-align: center;
  padding: 10px;
  background-color: #222;
  color: white;
  position: relative;
  bottom: 0;
  width: 100%;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.content-container {
  position: relative;
  z-index: 1; /* This ensures the content is displayed on top of the MatrixRain background */
}