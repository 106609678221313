body {
  overflow-y: auto;
  background-color: #1C212B;
  font-family: 'open sans', sans-serif;
  font-size: 20px;
  color: #c4c4c4;
}

h1 {
  font-size: 32px;
  font-weight: 400;
}

a, .link {
  color: #F4B62A; /* Matrix green */
}

code {
  font-family: 'Courier New', Courier, monospace;
}