.T-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1C212B;
    border-radius: 3px;
    width: 100%;
    max-width: 1200px; /* Ensures chart doesn't shrink too much */
    height: 500px; /* Default height */
    min-width: 1200px; /* Prevents excessive shrinking */
}

/* Ensure the chart is fullscreen in mobile horizontal mode */
@media (max-width: 768px) and (orientation: landscape) {
    .T-container {
        height: 90vh; /* Make the chart take up almost the full screen height */
        width: 100%;
        max-width: 100vw;
        min-width: auto;
    }
}


/* Adjustments for very small screens */
@media (max-width: 480px) {
    .T-container {
        height: 400px; /* Reduce height for smaller screens */
    }
}
