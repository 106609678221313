/* BalanceWithChart.css */

.balance-with-chart {
    padding: 20px;
    text-align: left;
  }
  
  .balance-chart-container {
    display: flex;
    justify-content: space-between; /* Space between the table and the chart */
    align-items: flex-start; /* Align the table and chart at the top */
    margin-top: 20px;
  }
  .balance-chart {
    margin-top: 0;
  }
  .balance-table {
    flex: 1;
    margin-right: 20px; /* Add some space between the table and the chart */
  }
  
  .balance-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .balance-table th,
  .balance-table td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .balance-chart {
    width: 400px; /* Set the width of the chart */
  }

  .active-tab {
    border-color: yellow;
    background-color: yellow;
    margin: 5px;
  }
  .not-active-tab {
    border-color: #000;
    margin: 5px;
  }
  

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  .close-modal-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #fff;
  }
  
  .close-modal-btn:hover {
    color: #ff4d4d;
  }
  