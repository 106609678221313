/* Ensure the chart and table stack properly */
.balance-chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Reduce Pie Chart size */
.balance-chart {
  width: 200px; /* Set smaller width */
  height: 200px; /* Set smaller height */
  max-width: 100%;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .balance-chart {
    width: 200px; /* Even smaller on mobile */
    height: 200px;
  }
}


/* Table container for proper fitting */
.balance-table {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

/* Table styles */
.balance-table table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Allows dynamic width distribution */
}

/* Table header and cell styling */
.balance-table th,
.balance-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  white-space: nowrap; /* Prevents text wrapping */
}

/* Reduce font size and adjust column spacing on mobile */
@media screen and (max-width: 600px) {
  .balance-table th,
  .balance-table td {
    font-size: 12px; /* Reduce font size for better readability */
    padding: 5px; /* Reduce padding to prevent overlap */
  }

  .transfer-btn {
    font-size: 12px;
    padding: 5px 8px; /* Smaller button */
  }
}

/* Hide unnecessary columns on smaller screens */
@media screen and (max-width: 768px) {
  .balance-table th:nth-child(4),
  .balance-table td:nth-child(4),
  .balance-table th:nth-child(5),
  .balance-table td:nth-child(5),
  .balance-table th:nth-child(6),
  .balance-table td:nth-child(6),
  .balance-table th:nth-child(7),
  .balance-table td:nth-child(7) {
    display: none; /* Hide Frozen, Position, and UnrealisedPNL */
  }
}

/* Transfer Button */
/* Transfer Button */
.transfer-btn {
  background: #F4B62A;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  gap: 5px;
}

.transfer-btn:hover {
  background: #e0a421;
}

/* Transfer Icon - Default Hidden */
.transfer-icon {
  display: none;
  font-size: 12px;
}

/* On Mobile: Show Only Icon, Hide Text */
@media screen and (max-width: 600px) {
  .transfer-text {
    display: none;
  }

  .transfer-icon {
    display: inline-block;
    color: #e0a421;
    font-size: 16px;
  }

  .transfer-btn {
    padding: 5px;
    background: none;
    border: none;
    cursor: pointer;
  }

  .transfer-btn:hover {
    background: none;
  }
}


