.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    width: 40%;
    background-color: #1d1c1c;
  }
  
  .profile-code-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    text-align: left;
    margin-top: 20px;
  }
  
  .profile-container {
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 20px;
    margin: 20px;
    width: 100%;
  }
  
  @media screen and (max-width: 1599px) {
    .profile-container {
      width: 100%;
    }
  }
  
  .profile-details {
    display: flex;
    flex-direction: column;
    align-items: left;
  }
  
  .profile-details h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  
  @media screen and (max-width: 1599px) {
    .code-container {
      width: 100%;
    }
  }
  
  .code-tabs {
    display: flex;
    justify-content: flex-start;
  }
  
  .code-tab {
    padding: 10px 15px;
    background-color: #eaeaea;
    color: #333;
    cursor: pointer;
  }
  
  .active {
    background-color: #333;
    color: #c4c4c4;
  }
  
  .code-snippet {
    white-space: pre-wrap;
  }
  