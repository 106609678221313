/* General Container */
.Info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    background-color: #1C212B;
    border-radius: 3px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
    width: 95%;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 2%;
    font-size: 14px;
    overflow-x: auto; /* Allows horizontal scroll on smaller devices */
}

/* Left-align the title */
.Info h3 {
    color: #c4c4c4;
    font-weight: 800;
    text-align: left; /* ⬅ Ensures left alignment */
}

/* Table Styles */
.Info table {
    width: 100%;
    border-collapse: collapse;
    color: #c4c4c4;
}

/* Table Headers and Cells */
.Info th, .Info td {
    border: none;
    border-bottom: 1px solid #303844;
    padding: 0.5rem;
    text-align: left;
    color: #c9c2c2;
}

/* Header Clickable Sorting */
.Info th {
    background-color: transparent;
    cursor: pointer;
    border-top: 1px solid transparent;
}

/* Sorting Icon */
.sort-icon {
    font-size: 0.5em;
    vertical-align: middle;
}

/* Search Bar */
.Info .search-bar {
    margin: 1rem 0;
    padding: 0.5rem;
    border-radius: 3px;
    border: 1px solid #303844;
    background-color: transparent;
    color: #c4c4c4;
    width: 100%;
    text-align: left;
}

/* Spinner */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Create Bot Button */
.Info .create-bot-btn {
    padding: 0.5rem 1rem;
    border: none;
    background-color: transparent;
    color: #E3A419;
    cursor: pointer;
    transition: font-weight 0.3s ease;
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
}

.Info .create-bot-btn:hover {
    font-weight: 600;
}

/* Hide unnecessary columns on mobile */
@media (max-width: 768px) {
    .Info th:nth-child(2),  /* Price */
    .Info td:nth-child(2),
    .Info th:nth-child(3),  /* Volume 24h */
    .Info td:nth-child(3),
    .Info th:nth-child(4),  /* High 24h */
    .Info td:nth-child(4),
    .Info th:nth-child(5),  /* Low 24h */
    .Info td:nth-child(5) {
        display: none; /* Hides columns on mobile */
    }

    .Info th, .Info td {
        font-size: 12px; /* Adjust font size for better fit */
        padding: 10px; /* More space for readability */
    }

    /* Adjust button for better touch support */
    .Info .create-bot-btn {
        font-size: 12px;
        padding: 6px 10px;
    }
}
