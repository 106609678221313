body {
    margin: 0;
    padding-top: 60px; /* Adjust for navbar height */
}

main {
    margin: 16px;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1C212B;
    padding: 12px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

/* Left Section (Logo + Menu) */
.navbar__left {
    display: flex;
    align-items: center;
    gap: 15px;
}

/* Navbar Menu (Desktop) */
.navbar__menu {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar__menu a {
    font-size: 14px;
    text-decoration: none;
    color: #c4c4c4;
    transition: color 0.3s ease;
    padding: 5px 10px;
}

.navbar__menu a:hover {
    color: #F4B62A;
}

/* Always visible Exchange Switcher */
.navbar__right {
    display: flex;
    align-items: center;
}

/* Logo */
.navbar__logo {
    width: 40px;
    height: 40px;
}

/* Mobile Toggle Button */
.navbar__toggle {
    display: none;
    background: none;
    border: none;
    color: #c4c4c4;
    font-size: 24px;
    cursor: pointer;
}

/* Mobile Menu (Hidden by Default) */
@media (max-width: 768px) {
    .navbar__menu {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #1C212B;
        padding: 15px;
        display: none;
    }

    .navbar__menu.open {
        display: flex;
    }

    .navbar__toggle {
        display: block;
    }
}
